import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {isEmpty} from "@core/utils/filter";

export default function stocklogUseList(config) {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'log_id', label: '日志id', sortable: true },
    { key: 'stock_id', label: '库存ID', sortable: true },
    // { key: 'company_id', label: '企业ID', sortable: true },
    { key: 'prono', label: '69码'},
    { key: 'name', label: '名称', sortable: true },
    // { key: 'company_name', label: '企业名称', sortable: true },
    // { key: 'warehouse_id', label: '仓库ID', sortable: true },
    { key: 'warehouse_name', label: '仓库名称', sortable: true },
    // { key: 'stock_no', label: '库存编号', sortable: true },
    { key: 'log_type', label: '变更类型', sortable: true },
    // { key: 'log_cost', label: '变更成本', sortable: true },
    // { key: 'log_qty', label: '变更数量', sortable: true },
    { key: 'source_qty', label: '变更前数量', sortable: true },
    { key: 'log_qty_sign', label: '变更数量', sortable: true },
    { key: 'after_qty', label: '变更后数量', sortable: true },
    { key: 'status', label: '库存状态', sortable: true },
    // { key: 'product_id', label: '商品ID', sortable: true },
    // { key: 'product', label: '产品', sortable: true },
    // { key: 'sku', label: 'SKU', sortable: true },
    // { key: 'image', label: '图片', sortable: true },
    // { key: 'cost', label: '成本', sortable: true },
    // { key: 'batch_no', label: '产品批次', sortable: true },
    // { key: 'production_date', label: '生产日期', sortable: true },
    // { key: 'expiry_date', label: '有效日期', sortable: true },
    { key: 'remark', label: '备注'},
    { key: 'add_time', label: '操作时间', sortable: true },
    { key: 'creator', label: '操作人', sortable: true },
    { key: 'door', label: '相关订单',},
    { key: 'state', label: '回滚', sortable: true },
        // { key: 'actions', label: '操作' },
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('log_id')
  const isSortDirDesc = ref(true)
  const condition = ref({
    productId:config.productid===0?null:config.productid,
    stockId:config.stockid===""?null:config.stockid,
    state:{"label":"否","value":1}
  })

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit, searchKey], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    const warehouseArray = []
    if (!isEmpty(condition.value.warehouseArray)){
      condition.value.warehouseArray.forEach((value, index, array) => {
        warehouseArray.push(value.value)
      })
    }
    store
      .dispatch('stocklog/search', {
        search_key: searchKey.value,
        start: start.value,
        limit: limit.value,
        order_by: orderBy.value,
        product_id:config.productid===0?condition.value.productId:config.productid,
        stock_id:config.stockid===""?condition.value.stockId:config.stockid,
        code:condition.value.productCode,
        productName:condition.value.productName,
        relational_no:condition.value.relationalNo,
        relational_id:condition.value.relationalId,
        order_type:condition.value.orderType==null?null:condition.value.orderType.value,
        state:condition.value.state==null?null:condition.value.state.value,
        log_type:condition.value.logType==null?null:condition.value.logType.value,
        warehouseIds:warehouseArray.join(","),
        add_time_str:condition.value.add_time_str,
        order_desc: isSortDirDesc.value === true ? 'desc':'',
      })
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        for (let i = 0; i < list.length; i++) {
          if (i<list.length-1){
            if (list[i].stock_id == list[i+1].stock_id&&list[i].source_qty == list[i+1].source_qty){
              list[i].color = 'table-danger'
              list[i+1].color = 'table-danger'
            }
            if (list[i].stock_id == list[i+1].stock_id&&list[i].source_qty != list[i+1].after_qty){
              list[i].color = 'table-warning'
              list[i+1].color = 'table-warning'
            }
          }
        }
        listTotals.value = data.ext.totals
        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '仓库变更日志列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,

    refetchData,
    condition,

    // Extra Filters
  }
}
