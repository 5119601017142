<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <!-- Search -->
          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="advanced_search"
              >
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-middle">高级搜索</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
        <b-card
            v-show="advanced_search_modal"
        >
          <b-form
              @submit.prevent="refetchDatas"
              @reset.prevent="resetCondition"
          >
            <b-row>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="商品69码:"
                    label-for="productCode"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="condition.productCode"
                      name="productCode"
                      class="d-inline-block mr-1"
                      size="sm"
                      placeholder="请输入商品69码"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="商品名称:"
                    label-for="product_name"
                    label-size="sm"
                    placeholder="请输入商品名称"
                >
                  <b-form-input
                      v-model="condition.productName"
                      name="productName"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="商品ID:"
                    label-for="product_id"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="condition.productId"
                      name="productId"
                      class="d-inline-block mr-1"
                      type="number"
                      size="sm"
                      placeholder="请输入商品ID"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="库存ID:"
                    label-for="product_id"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="condition.stockId"
                      name="stockId"
                      class="d-inline-block mr-1"
                      type="number"
                      size="sm"
                      placeholder="请输入库存ID"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="关联单编号:"
                    label-for="order_no"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="condition.relationalNo"
                      name="relationalNo"
                      class="d-inline-block mr-1"
                      size="sm"
                      placeholder="请输入关联单编号"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="关联单id:"
                    label-for="order_no"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="condition.relationalId"
                      name="relationalId"
                      class="d-inline-block mr-1"
                      size="sm"
                      placeholder="请输入关联单id"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="关联订单分类:"
                    label-for="orderType"
                    label-size="sm"
                >
                  <v-select
                      id="status"
                      :options="getCodeOptions('stock_log_order_type')"
                      v-model="condition.orderType"
                      name="orderType"
                      class="select-size-sm"
                      placeholder="请选择关联订单分类"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="变更类型:"
                    label-for="logType"
                    label-size="sm"
                >
                  <v-select
                      id="source"
                      :options="getCodeOptions('stock_log_type')"
                      v-model="condition.logType"
                      name="logType"
                      class="select-size-sm"
                      placeholder="请选择变更类型"
                  />
                </b-form-group>
              </b-col>

              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="所在仓库"
                    label-for="user_level"
                    label-size="sm"
                    class="mb-1"
                >
                  <v-select
                      v-model="condition.warehouseArray"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      multiple
                      label="label"
                      :options="getCodeOptions('warehouse')"
                      class="select-size-sm"
                      :close-on-select="false"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="是否回滚:"
                    label-for="state"
                    label-size="sm"
                >
                  <v-select
                      id="state"
                      :options="stateArray"
                      v-model="condition.state"
                      name="state"
                      class="select-size-sm"
                      placeholder="请选择是否回滚"
                  />
                </b-form-group>
              </b-col>

              <b-col md="4" cols="12">
                <xy-input-button label="操作日期"
                                 type="time"
                                 v-on:change="fromChildren($event,['add_time_str'])"
                                 :params="['add_time_str']"
                                 :value="condition.add_time_str"
                >
                </xy-input-button>
              </b-col>

              <b-col
                  cols="12"
                  md="12"
                  class="demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="advanced_search"
                >
                  <span class="align-right">关闭</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="exportData"
                >
                  <span class="align-right">导出</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>

      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="searchList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="orderBy"
        show-empty
        empty-text="未找到记录"
        :sort-desc.sync="isSortDirDesc"
        :tbody-tr-class="rowClass"
      >

        <!-- Columns -->
        <template #cell(stock_no)="data">
          [#{{data.item.stock_id}}]{{data.item.stock_no}}
        </template>

        <template #cell(log_type)="data">
          {{getCodeLabel('stock_log_type', data.item.log_type)}}
        </template>

        <template #cell(log_qty_sign)="data">
          {{data.item.log_qty_sign}}
        </template>

        <template #cell(status)="data">
<!--          <span :class="'text-'+getCode('stock_status', data.item.status).color">{{ getCodeLabel("stock_status", data.item.status)}}</span>-->
          <b-badge
              pill
              :variant="`light-${getCodeColor('stock_status', data.item.status)}`"
          >
            {{ getCodeLabel("stock_status", data.item.status)}}
          </b-badge>
        </template>

        <template #cell(prono)="data">
          {{data.item.ext.productcode}}
        </template>

        <template #cell(name)="data">
          [{{data.item.product_id}}]{{data.item.name}}{{isGift(data.item.is_gift)}}
          <feather-icon
              icon="TwitchIcon"
              size="21"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.html="
              '<div class=text-left>'+
              'stockid：'+data.item.stock_id+
              '<br>'+'库存编号：'+data.item.stock_no+
              '<br>'+'商品69码：'+data.item.ext.productcode+
              '<br>'+'产品批次：'+data.item.batch_no+
              '<br>'+'开始：'+toDate(data.item.production_date)+
              '<br>'+'结束：'+toDate(data.item.expiry_date)+
              '</div>'"
              variant="success"
          />
        </template>

        <template #cell(add_time)="data">
          {{toTime(data.item.add_time)}}
        </template>

        <template #cell(creator)="data">
          {{getCodeLabel('user', data.item.creator)}}
        </template>

        <template #cell(door)="data">
          <a target="_blank" :href="toURL(data.item.order_type,data.item.relational_id)">
            {{getCodeLabel('stock_log_order_type', data.item.order_type)}}
          </a>
          <br>
          NO：{{data.item.relational_no}} ID：{{data.item.relational_id}}
          <div>{{data.item.order_type === 4 ? "销售单：" + data.item.ext.orderno : ''}}</div>
        </template>

        <!-- Column: State -->
        <template #cell(state)="data">
          <b-badge
              pill
              :variant='`light-${data.item.state===1?"secondary":"danger"}`'
          >
            {{data.item.state===1?"否":"是"}}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'apps-stocklog-view', query: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">浏览</span>
            </b-dropdown-item>

            <b-dropdown-item :to="{ name: 'apps-stocklog-edit', query: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">编辑</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="start"
              :total-rows="listTotals"
              :per-page="limit"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BIcon, BootstrapVueIcons,
  VBTooltip,BAlert,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  avatarText,
  toTime,
  toDate,
  getCodeOptions,
  getCode,
  getCodeLabel,
  getCodeColor,
  isEmpty
} from '@core/utils/filter'
import stocklogUseList from './stocklogUseList'
import stocklogStore from './stocklogStore'
import Ripple from 'vue-ripple-directive'
import XyInputButton from "@/views/components/xy/XyInputButton";
import {useToast} from "vue-toastification/composition";
import axios from "@/libs/axios";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BIcon,
    BootstrapVueIcons,
    VBTooltip,
    BAlert,
    XyInputButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  methods: {
    changeState: function(data) {
      const state = 1 - data.state
      store.dispatch('stocklog/state', {id: data.id, state: state}).then(res => {
        data.state = state
      })
    },
    resetCondition() {
      this.condition = {}
      this.refetchData();
    },
    rowClass(item, type) {
      if (!item || type !== 'row') { return }
      if (item.color=='table-danger') { return  'table-danger'}
      if (item.color=='table-warning') { return  'table-warning'}
    },
  },
  props: {
    productid: {
      type: Number,
      default: 0,
    },
    stockid: {
      type: String,
      default: '',
    },
  },
  data(){
    return{
      advanced_search_modal: false,
      stateArray:[{"label":"是","value":0},{"label":"否","value":1},{"label":"全部","value":null}]
    }
  },
  setup(props) {
    const toast = useToast()
    // Register module
    if (!store.hasModule('stocklog')) store.registerModule('stocklog', stocklogStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('stocklog')) store.unregisterModule('stocklog')
    })

    const isGift = function (params) {
      if (params===1){
        return "(赠)"
      }else {
        return null
      }
    }

    const exportData = function () {
      const condition = this.condition
      const warehouseArray = []
      if (!isEmpty(condition.warehouseArray)){
        condition.warehouseArray.forEach((value, index, array) => {
          warehouseArray.push(value.value)
        })
      }
      axios({
            url: '/api/exportexcelfile/exportStockLog',
            method:'get',
            params: {
              search_key: searchKey,
              order_by: orderBy,
              product_id:condition.productId,
              stock_id:condition.stockId,
              code:condition.productCode,
              name:condition.productName,
              relational_no:condition.relationalNo,
              relational_id:condition.relationalId,
              order_type:condition.orderType==null?null:condition.orderType.value,
              state:condition.state==null?null:condition.state.value,
              log_type:condition.logType==null?null:condition.logType.value,
              warehouseIds:warehouseArray.join(","),
              add_time_str:condition.add_time_str,
            },
            responseType: 'blob',
          }
      ).then(res => {
        let data = new Blob([res.data], { type: 'application/vnd.ms-excel' })   //接收的是blob，若接收的是文件流，需要转化一下
        let filename = "库存日志-"+new Date().getTime();
        /*-------------*/
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = URL.createObjectURL(data);
        link.setAttribute('download',filename+'.xlsx')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
    }

    const toURL = function (urlType,urlId){
      //采购入库通知单
      if(urlType===1){
        return "/apps/purchaseinbound/warehouseList?inbound_id=" + urlId+"&isStockLog=1"
      }

      if(urlType===12){
        return "/apps/purchaseoutbound/list?outbound_id=" + urlId+"&isStockLog=1"
      }

      if(urlType===13){
        return "/apps/outbound/list?outbound_id=" + urlId+"&isStockLog=1"
      }
      //库存调拨通知单
      else if(urlType===2 || urlType===5 || urlType===6){
        return "/apps/allotoutbound/warehouse?outbound_id=" + urlId+"&isStockLog=1"
      }
      //售后退货通知单
      else if(urlType===3){
        return  "/apps/salesreturnbound/warehouseList?returnbound_id=" + urlId+"&isStockLog=1"
      }
      //销售出库通知单
      else if(urlType===4 || urlType===10){
        return "/apps/saleso" +
            "utbound/salesoutboundwarehouselist?outbound_id=" + urlId+"&isStockLog=1"
      }
      //非标生产单
      else if(urlType===11){
        return "/apps/produceorder/list?produce_id=" + urlId+"&isStockLog=1"
      }

      //锁库操作  库存修改  库存状态转换  库存数量转换
      else if(urlType===0 || urlType===7 || urlType===8 || urlType===9|| urlType===15){
        return "###"
      }
    }

    const advanced_search = function () {
      this.advanced_search_modal = this.advanced_search_modal ? false : true;
    }

    const fromChildren = function (params, modal) {
      for (let i = 0; i < modal.length; i++) {
        this.condition[modal[i]] =params==null?null:params[modal[i]]
      }
      this.$forceUpdate()
    }

    const refetchDatas = function () {
      if (this.condition.relationalId!=null&&this.condition.relationalId!=''){
        if (this.condition.orderType==null){
          toast.error("请选择订单分类")
          return false
        }else {
          refetchData()
        }
      }else {
        refetchData()
      }
    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      condition,

      // UI
    } = stocklogUseList({
      productid:props.productid,
      stockid:props.stockid,
    })

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      toTime, toDate,
      // UI
      isGift,
      exportData,
      toURL,
      advanced_search,
      fromChildren,
      refetchDatas,

      condition,

    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
