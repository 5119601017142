<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0 list-table"
    >
      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="7"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示:</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
<!--            <div class="demo-spacing-0">-->
<!--              <b-alert-->
<!--                  variant="primary"-->
<!--                  show-->
<!--              >-->
<!--                <div class="alert-body" >-->
<!--                  <span><strong>总库存金额:{{allCost}}</strong> </span>-->
<!--                </div>-->
<!--              </b-alert>-->
<!--            </div>-->
          </b-col>


          <b-col
              cols="12"
              md="5"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  class="mr-1"
                  @click="advanced_search"
              >
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-middle">高级搜索</span>
              </b-button>
            </div>
          </b-col>

        </b-row>

        <b-card
            v-show="advanced_search_modal"
        >
          <b-form
              @submit.prevent="searchByCondition"
              @reset.prevent="resetCondition"
          >
            <b-row>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="仓库:"
                    label-for="contact"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="wareHouseName"
                      size="sm"
                      v-model="wareHouseName"
                      @click="showSingleModal('仓库')"
                      placeholder="点击选择仓库"
                      readonly=""
                  />
                </b-form-group>
              </b-col>

              <!-- Search 仓库、商品名称、商品69码 -->
              <b-col
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="商品名称:"
                    label-for="contact"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      v-model="searchKeyName"
                      class="d-inline-block mr-1"
                      placeholder="支持输入空格后多级搜索"
                  />

                </b-form-group>
              </b-col>

<!--              <b-col md="4">-->
<!--                <b-form-group-->
<!--                    label-cols="3"-->
<!--                    label-cols-lg="3"-->
<!--                    label="供应商:"-->
<!--                    label-for="contact"-->
<!--                    label-size="sm"-->
<!--                    class="mb-1"-->
<!--                >-->
<!--                  <b-form-input-->
<!--                      size="sm"-->
<!--                      v-model="supplierName"-->
<!--                      @click="showSingleModal('供应商')"-->
<!--                      placeholder="点击选择供应商"-->
<!--                      readonly=""-->
<!--                  />-->
<!--                </b-form-group>-->
<!--              </b-col>-->

<!--              <b-col md="4">-->
<!--                <b-form-group-->
<!--                    label-cols="3"-->
<!--                    label-cols-lg="3"-->
<!--                    label="采购负责人:"-->
<!--                    label-for="contact"-->
<!--                    label-size="sm"-->
<!--                    class="mb-1"-->
<!--                >-->
<!--                  <b-form-input-->
<!--                      size="sm"-->
<!--                      v-model="PUserName"-->
<!--                      @click="showSingleModal('采购负责人')"-->
<!--                      placeholder="点击选择采购负责人"-->
<!--                      readonly=""-->
<!--                  />-->
<!--                </b-form-group>-->
<!--              </b-col>-->

              <b-col
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="商品69码:"
                    label-for="contact"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      v-model="code"
                      class="d-inline-block mr-1"
                      placeholder="输入商品69码"
                  />

                </b-form-group>
              </b-col>

              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="分组"
                    label-for="category"
                    label-size="sm"
                >
                  <b-form-input
                      id="chooseId"
                      size="sm"
                      readonly
                      placeholder="请选择分类"
                      v-b-modal.modal-select-category
                      v-model="product_category_name"
                      class="d-inline-block mr-1"
                  />
                </b-form-group>
              </b-col>

              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="负库存"
                    label-for="need_advance"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-checkbox
                      name="check-button"
                      switch
                      inline
                      id="need_advance"
                      v-model="negativeStock"
                      value="1"
                      unchecked-value="0"
                  ></b-form-checkbox>
                </b-form-group>
              </b-col>

              <b-col
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="库存id:"
                    label-for="stockIds"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      v-model="stockIds"
                      class="d-inline-block mr-1"
                      placeholder="输入库存id"
                      type="number"
                  />

                </b-form-group>
              </b-col>


              <b-col
                  cols="12"
                  md="12"
                  class="demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>

<!--                <b-button-->
<!--                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"-->
<!--                    variant="outline-primary"-->
<!--                    type="reset"-->
<!--                    @click="createExcel"-->
<!--                >-->
<!--                  <span class="align-right">导出</span>-->
<!--                </b-button>-->

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="advanced_search"
                >
                  <span class="align-right">关闭</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>

      </div>

      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          hover
          small
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
      >

        <template v-slot:custom-foot="data">
          <b-tr>
            <b-td :colspan="9" variant="primary">
              <span><strong>合计</strong></span>
            </b-td>
            <b-td :colspan="1" variant="primary">
              <span><strong>{{allCost}}</strong></span>
            </b-td>
          </b-tr>
        </template>

        <template #row-details="row">
          <!--          <b-card v-for="i in row.item.ext.items" :key="i.name">-->
          <b-card>
            <div>
              <b-table striped hover :items="row.item.ext.item" :fields="fields">

                <template #cell(company_id)="data">
                  {{ getCodeLabel("company", data.item.company_id) }}
                </template>

                <template #cell(status)="data">
                  <b-badge
                      pill
                      :variant="`light-${getCodeColor('stock_status', data.item.status)}`"
                  >
                    {{ getCodeLabel("stock_status", data.item.status) }}
                  </b-badge>
                </template>
                <template #cell(location_fullname)="data">
                  <b-badge
                      pill
                      :variant="`light-${getCodeColor('warehouse_location',data.value)}`"
                  >
                    {{ getCodeLabel('warehouse_location', data.value) }}
                  </b-badge>
                </template>

                <template #cell(creator)="data">
                  {{ getCodeLabel("user", data.item.creator) }}
                </template>

                <template #cell(name)="data">
                  {{data.item.name}}{{ data.item.is_gift===1?"(赠)":"" }}
                </template>

                <template #cell(add_time)="data">
                  {{ toDate(data.item.add_time) }}
                </template>

                <template #cell(qty)="data">
                  库存：{{ getCodeLabel('setting','start_inventory')!=='1'?data.item.qty:0 }}
                  / 可用库存：{{ (getCodeLabel('setting','start_inventory')!=='1'?data.item.qty:0)-(getCodeLabel('setting','start_inventory')!=='1'?data.item.ext.stockItemLockQty:0) }}
                  / 占用库存：{{ getCodeLabel('setting','start_inventory')!=='1'?data.item.ext.stockItemLockQty:0 }}
<!--                  <feather-icon-->
<!--                      icon="TwitchIcon"-->
<!--                      size="21"-->
<!--                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"-->
<!--                      v-b-tooltip.hover.html="-->
<!--                              '<div class=text-left>'+-->
<!--                              '库存编号：'+data.item.stock_no+-->
<!--                              '<br>'+'产品批次：'+data.item.batch_no+-->
<!--                              '<br>'+'开始：'+toDate(data.item.production_date)+-->
<!--                              '<br>'+'结束：'+toDate(data.item.expiry_date)+-->
<!--                              '<br>'+'成本：'+data.item.cost+-->
<!--                              '</div>'"-->
<!--                      variant="success"-->
<!--                  />-->
                </template>

                <template #cell(cost)="data">
                  {{ data.item.cost }}
                </template>

                <template #cell(production_date)="data">
                  {{ toDate(data.item.production_date) }}
                </template>

                <template #cell(productcode)="data">
                  {{ data.item.ext.productcode }}
                </template>

                <template #cell(boxquantity)="data">
                  {{ data.item.ext.boxquantity }}
                </template>

                <template #cell(productunit)="data">
                  {{ getCodeLabel("product_unit", data.item.ext.productunit) }}
                </template>

                <template #cell(productunit)="data">
                  {{ getCodeLabel("product_unit", data.item.ext.productunit) }}
                </template>

                <template #cell(actions)="data">
                  <b-dropdown
                      variant="link"
                      no-caret
                  >

                    <template #button-content>
                      <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="align-middle text-body"
                      />
                    </template>

                    <b-dropdown-item v-b-modal.modal-stock-log @click="changeId(data.item)">
                      <feather-icon icon="EditIcon"/>
                      <span class="align-middle ml-50">库存日志</span>
                    </b-dropdown-item>


                    <!--yang-->
                    <div v-if="user.user_id===data.item.ext.applierId">
                    <b-dropdown-item :to="{name:'apps-purchaseinbound-list',query:{id:data.item.purchaseorder_id,productId:data.item.product_id}}">
                      <feather-icon icon="EditIcon" />
                      <span class="align-middle ml-50">采购退货</span>
                    </b-dropdown-item>
                    </div>

                    <b-dropdown-item v-b-modal.stockchangemodel @click="getProduct(data.item)">
                      <feather-icon icon="EditIcon"/>
                      <span class="align-middle ml-50">规格转换</span>
                    </b-dropdown-item>

                    <b-dropdown-item v-b-modal.modal-state-transfer @click="changeTransfer(data.item)">
                      <feather-icon icon="EditIcon"/>
                      <span class="align-middle ml-50">库位转换</span>
                    </b-dropdown-item>



                    <div v-if="user.role_id!=9&&user.role_id!=10">
                      <b-dropdown-item v-b-modal.modal-stock-change @click="changeStock(data.item)"
                                       v-if="user.user_id==23">
                        <feather-icon icon="EditIcon"/>
                        <span class="align-middle ml-50">物流库存修改</span>
                      </b-dropdown-item>

                      <b-dropdown-item v-b-modal.modal-yanfa-stock-change @click="changeStock(data.item)"
                                       v-if="user.role_id==14">
                        <feather-icon icon="EditIcon"/>
                        <span class="align-middle ml-50">研发库存修改</span>
                      </b-dropdown-item>

                      <b-dropdown-item v-b-modal.modal-stock-cost @click="changeStock(data.item)" v-if="readonly===0">
                        <feather-icon icon="EditIcon"/>
                        <span class="align-middle ml-50">修改成本</span>
                      </b-dropdown-item>

                      <b-dropdown-item @click="updateSalesOrderCost(data.item)" v-if="readonly===0">
                        <feather-icon icon="EditIcon"/>
                        <span class="align-middle ml-50">调用接口</span>
                      </b-dropdown-item>
                    </div>
                  </b-dropdown>
                </template>
                <!--                <template #cell(ext)="data">-->
                <!--                  {{ data.item.ext.code ? data.item.ext.code : '' }}-->
                <!--                </template>-->

                <!--                <template #cell(name)="data">-->
                <!--                  [#{{ data.item.product_id }}]{{ data.item.name }}-->
                <!--                </template>-->

                <!--                &lt;!&ndash;库存转态&ndash;&gt;-->
                <!--                <template #cell(status)="data">-->
                <!--                  <b-badge-->
                <!--                      pill-->
                <!--                      :variant="`light-${getCodeColor('stock_status', data.item.status)}`"-->
                <!--                  >-->
                <!--                    {{ getCodeLabel("stock_status", data.item.status)}}-->
                <!--                  </b-badge>-->
                <!--                </template>-->

                <!--                <template #cell(gross_profit_rate)="data">-->
                <!--                  {{ data.item.ext.gross_profit_rate ? data.item.ext.gross_profit_rate : ''}}-->
                <!--                </template>-->

              </b-table>
            </div>
          </b-card>
        </template>

        <template #cell(qty)="data">
          {{ getCodeLabel('setting','start_inventory')!=='1'?data.item.qty:0 }}
        </template>

        <template #cell(canUseQty)="data">
          {{ (getCodeLabel('setting','start_inventory')!=='1'?data.item.qty:0)-(data.item.ext.stockLockQty) }}
        </template>

        <template #cell(lockQty)="data">
          {{data.item.ext.stockLockQty}}
        </template>
        <!--        仓库-->
        <template #cell(warehouse_id)="data">
          <b-form-checkbox v-model="data.detailsShowing"
                           name="check-button"
                           @change="data.toggleDetails"
                           button-variant="default"
                           size="sm"
                           button>
            <feather-icon
                icon="MinusSquareIcon"
                size="15"
                class="align-middle text-body"
                v-show="data.detailsShowing==true"
            />
            <feather-icon
                icon="PlusSquareIcon"
                size="16"
                class="align-middle text-body"
                v-show="data.detailsShowing==false"
            />
          </b-form-checkbox>
          {{ getCodeLabel('warehouse', data.item.warehouse_id) }}
        </template>


        <template #cell(name)="data">
          [#{{ data.item.product_id }}]{{ data.item.name }}
        </template>

        <template #cell(location_fullname)="data">
          <b-badge
              pill
              :variant="`light-${getCodeColor('warehouse_location',data.item.location_fullname)}`"
          >
            {{ getCodeLabel('warehouse_location', data.item.location_fullname) }}
          </b-badge>
        </template>

        <template #cell(productunit)="data">
          {{data.item.ext==null?'无':getCodeLabel("product_unit", data.item.ext.item[0].ext.productunit)}}
        </template>

        <template #cell(boxquantity)="data">
          {{data.item.ext==null?'无':data.item.ext.item[0].ext.boxquantity}}
        </template>

        <template #cell(code)="data">
          {{data.item.ext==null?'无':data.item.ext.item[0].ext.productcode}}
        </template>

        <template #cell(status)="data">
          <b-badge
              pill
              :variant="`light-${getCodeColor('stock_status', data.item.status)}`"
          >
            {{ getCodeLabel("stock_status", data.item.status) }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>
        </b-row>

        <b-modal
            id="modal-single"
            ok-only
            ok-title="确认"
            @ok="onSelectSingle"
            cancel-title="取消"
            centered
            size="lg"
            :title="'请选择'+modalName"
            ref="singleModal"
        >

          <company-select
              ref="companySelect" :companyType="companyType" v-if="modalName ==='供应商' "
          >
          </company-select>

          <!-- :userDepartment="userDepartment"  判断负责人所属部门-->
          <user-select
              ref="userSelect" v-else-if="modalName === '采购负责人' "
          >
          </user-select>

          <WareHouseSelect
              ref="myWarehouseSelect" v-else-if="modalName === '仓库'">
          </WareHouseSelect>

        </b-modal>

      </div>
      <!--库存日志-->
      <b-modal
          id="modal-stock-log"
          ok-only
          centered
          size="xl"
          title="库存日志"
      >
        <stock-log-list :productid="productid" :stockid="stockid"></stock-log-list>
      </b-modal>

      <!--库存转换-->
      <b-modal
          id="stockchangemodel"
          ok-title="确认"
          cancel-title="取消"
          centered
          size="lg"
          @ok="checksubmit"
          no-close-on-backdrop
          no-close-on-esc
          :title="'规格转换'"
      >
        <b-col md="12">
          <div class="inner-card-title">转换前商品</div>
        </b-col>

        <!--        <div class="demo-spacing-0">-->
        <!--          <b-alert-->
        <!--              variant="primary"-->
        <!--              show-->
        <!--          >-->
        <!--            <div class="alert-body" >-->
        <!--              <span><strong>勾选产品，表示此产品需要出库</strong> </span>-->
        <!--            </div>-->
        <!--          </b-alert>-->
        <!--        </div>-->

        <b-col md="12">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="商品名称"
              label-for="cost_reference"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                id="1"
                size="sm"
                v-model="productname"
                readonly=""
            />
          </b-form-group>
        </b-col>

        <b-col md="12">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="商品69码"
              label-for="cost_reference"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                id="2"
                size="sm"
                v-model="productcode"
                readonly=""
            />
          </b-form-group>
        </b-col>

        <b-col md="12">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="商品价格"
              label-for="cost"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                size="sm"
                v-model="cost"
                readonly=""
            />
          </b-form-group>
        </b-col>

        <b-col md="12">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="单箱标准数量"
              label-for="cost_reference"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                id="3"
                size="sm"
                v-model="boxquantity"
                readonly=""
            />
          </b-form-group>
        </b-col>

        <b-col md="12">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="商品库存(扣除锁库)"
              label-for="cost_reference"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                size="sm"
                v-model="qty"
                readonly=""
            />
          </b-form-group>
        </b-col>

        <b-col md="12">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="转换商品库存"
              label-for="cost_reference"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                size="sm"
                v-model="wishQty"
                @change="changeQty($event)"
            />
          </b-form-group>
        </b-col>


        <b-col md="12">
          <div class="inner-card-title">转换后商品</div>
        </b-col>


        <b-col cols="12" >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="商品名称"
              label-for="brand_id"
              label-size="sm"
              class="mb-1"
          >
            <v-select
                size="5 "
                id="brand_id"
                :options="brandArray"
                :clearable="true"
                v-model="product_brandName"
                @input="changeBrandId($event)"
                placeholder="请选择商品"
                class="select-size-sm"
            />
          </b-form-group>
        </b-col>

        <!--        <b-col md="12">
                  <b-form-group
                      label-cols="3"
                      label-cols-lg="3"
                      label="商品名称"
                      label-for="cost_reference"
                      label-size="sm"
                      class="mb-1"
                  >
                    <b-form-input
                        id="4"
                        size="sm"
                        v-model="changedproductname"
                        readonly=""
                        v-b-modal.modal-select-product
                        placeholder="请选择商品"
                    />
                  </b-form-group>
                </b-col>-->

        <b-col md="12">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="商品69码"
              label-for="cost_reference"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                id="5"
                size="sm"
                v-model="changedproductcode"
                readonly=""
            />
          </b-form-group>
        </b-col>

        <b-col md="12">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="商品价格"
              label-for="changecost"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                size="sm"
                v-model="changecost"
                readonly=""
            />
          </b-form-group>
        </b-col>

        <b-col md="12">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="单箱标准数量"
              label-for="cost_reference"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                id="6"
                size="sm"
                v-model="changedboxquantity"
                readonly=""
            />
          </b-form-group>
        </b-col>

        <!--        <b-col md="12">-->
        <!--          <b-form-group-->
        <!--              label-cols="3"-->
        <!--              label-cols-lg="3"-->
        <!--              label="转换后商品数量"-->
        <!--              label-for="cost_reference"-->
        <!--              label-size="sm"-->
        <!--              class="mb-1"-->
        <!--          >-->
        <!--            <b-form-input-->
        <!--                size="sm"-->
        <!--                v-model="changedqty"-->
        <!--                @change="changeQty($event)"-->
        <!--            />-->
        <!--            按转换后单箱标准数量计算-->
        <!--          </b-form-group>-->
        <!--        </b-col>-->

        <b-col md="12">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="转换后商品数量"
              label-for="cost_reference"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                size="sm"
                v-model="changedqty"
                readonly
            />
          </b-form-group>
        </b-col>

        <b-col md="12">
          <b-form-group
              label-cols="1"
              label-cols-lg="1"
              label="原因"
              label-for="cost_reference"
              label-size="sm"
              class="mb-1"
          >
            <b-form-textarea
                id="remark"
                size="sm"
                v-model="stockChangeApplyReason"
                rows="3"
                max-rows="6"
            />
          </b-form-group>
        </b-col>


      </b-modal>

      <!--      选择商品-->



      <!--      <b-modal
                id="modal-select-product"
                ok-only
                ok-title="确认"
                @ok="onSelectProducts"
                cancel-title="取消"
                centered
                size="xl"
                title="选择商品"
            >
              <product-select
                  ref="myProductSelect"
              >
              </product-select>
            </b-modal>-->

      <!--物流库存修改-->
      <b-modal
          id="modal-stock-change"
          ok-only
          ok-title="确认"
          @ok="onChangeStock('wuliu')"
          cancel-title="取消"
          centered
          size="lg"
          title="物流库存修改"
      >

        <b-col md="12">
          <b-form-group
              label-cols="2"
              label-cols-lg="2"
              label="操作类型"
              label-for="type"
              label-size="sm"
              class="mb-1 required"
          >
            <v-select
                :options="changeStockType"
                :clearable="true"
                @input="changeSelect($event)"
                class="select-size-sm"
                placeholder="请选择操作类型"
            />
          </b-form-group>
        </b-col>

        <b-col md="12">
          <b-form-group
              label-cols="2"
              label-cols-lg="2"
              label="操作数量"
              label-for="cost_reference"
              label-size="sm"
              class="mb-1 required"
          >
            <b-form-input
                size="sm"
                v-model="changeNum"
                type="number"
            />
          </b-form-group>
        </b-col>

        <b-col md="12">
          <b-form-group
              label-cols="2"
              label-cols-lg="2"
              label="原因"
              label-for="cost_reference"
              label-size="sm"
              class="mb-1"
          >
            <b-form-textarea
                id="remark"
                size="sm"
                v-model="remark"
                rows="3"
                max-rows="6"
            />
          </b-form-group>
        </b-col>
      </b-modal>

      <!--研发库存修改-->
      <b-modal
          id="modal-yanfa-stock-change"
          ok-only
          ok-title="确认"
          @ok="onChangeStock('yanfa')"
          cancel-title="取消"
          centered
          size="lg"
          title="研发库存修改"
      >

        <b-col md="12">
          <b-form-group
              label-cols="2"
              label-cols-lg="2"
              label="操作类型"
              label-for="type"
              label-size="sm"
              class="mb-1 required"
          >
            <v-select
                :options="changeStockType"
                :clearable="true"
                @input="changeSelect($event)"
                class="select-size-sm"
                placeholder="请选择操作类型"
            />
          </b-form-group>
        </b-col>

        <b-col md="12">
          <b-form-group
              label-cols="2"
              label-cols-lg="2"
              label="操作数量"
              label-for="cost_reference"
              label-size="sm"
              class="mb-1 required"
          >
            <b-form-input
                size="sm"
                v-model="changeNum"
                type="number"
            />
          </b-form-group>
        </b-col>

        <b-col md="12">
          <b-form-group
              label-cols="2"
              label-cols-lg="2"
              label="原因"
              label-for="cost_reference"
              label-size="sm"
              class="mb-1"
          >
            <b-form-textarea
                id="remark"
                size="sm"
                v-model="remark"
                rows="3"
                max-rows="6"
            />
          </b-form-group>
        </b-col>
      </b-modal>

      <!--状态转移-->
      <b-modal
          id="modal-state-transfer"
          ok-only
          ok-title="确认"
          @ok="transfer"
          cancel-title="取消"
          centered
          size="lg"
          title="库位转换"
      >
        <b-row>

          <b-col md="5" style="margin-top: 20%">
            <b-card :header=goodOrBad>
              <b-col md="12">
                <b-form-group
                    label-cols="4"
                    label-cols-lg="4"
                    label="转换前库存"
                    label-for="cost_reference"
                    label-size="sm"
                    class="mb-1 required"
                >
                  <b-form-input
                      size="sm"
                      v-model="stock.qty"
                      readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                    label-cols="4"
                    label-cols-lg="4"
                    label="转换前仓位"
                    label-for="cost_reference"
                    label-size="sm"
                    class="mb-1 required"
                >
                  <b-form-input
                      size="sm"
                      :value="getCodeLabel('warehouse_location',stock.location_fullname)"
                      readonly
                  />
                </b-form-group>
              </b-col>
            </b-card>
          </b-col>

          <b-col md="1" style="padding-top: 30%">
            <b-col md="12">
              <feather-icon
                  icon="ArrowRightIcon"
                  size="30"
              />
            </b-col>

          </b-col>

          <b-col md="6">
            <b-card header="良品">
              <b-col md="12">
                <b-form-group
                    label-cols="4"
                    label-cols-lg="4"
                    label="转换后库存"
                    label-for="cost_reference"
                    label-size="sm"
                    class="mb-1 required"
                >
                  <b-form-input
                      size="sm"
                      v-model="goodStock"
                      type="number"
                      @change="changeStockNum('good')"
                  />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                    label-cols="4"
                    label-cols-lg="4"
                    label="转换后仓位"
                    label-for="cost_reference"
                    label-size="sm"
                    class="mb-1 required"
                >
                  <v-select
                      label="zh_label"
                      :options="warehouseLocationArray"
                      :clearable="true"
                      v-model="goodWarehouseLocation"
                      @input="changeWarehouseLocation('good',$event)"
                      class="select-size-sm"
                      placeholder="请选择仓位"
                      :disabled="stock.status==0&&stock.location_fullname=='0-0-0-0'"
                  >
                    <template #option="{ label }">
                      <b-badge
                          pill
                          :variant="`light-${getCodeColor('warehouse_location',label)}`"
                      >
                        {{ getCodeLabel('warehouse_location', label) }}
                      </b-badge>
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>

            </b-card>
          </b-col>
          <b-col md="6"></b-col>

          <b-col md="6" style="margin-top: -10%">
            <b-card header="不良品">
              <b-col md="12">
                <b-form-group
                    label-cols="4"
                    label-cols-lg="4"
                    label="转换后库存"
                    label-for="cost_reference"
                    label-size="sm"
                    class="mb-1 required"
                >
                  <b-form-input
                      size="sm"
                      v-model="badStock"
                      type="number"
                      @change="changeStockNum('bad')"
                  />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                    label-cols="4"
                    label-cols-lg="4"
                    label="转换后仓位"
                    label-for="cost_reference"
                    label-size="sm"
                    class="mb-1 required"
                >
                  <v-select
                      label="zh_label"
                      :options="warehouseLocationArray"
                      :clearable="true"
                      v-model="badWarehouseLocation"
                      @input="changeWarehouseLocation('bad',$event)"
                      class="select-size-sm"
                      placeholder="请选择仓位"
                  >
                    <template #option="{ label }">
                      <b-badge
                          pill
                          :variant="`light-${getCodeColor('warehouse_location',label)}`"
                      >
                        {{ getCodeLabel('warehouse_location', label) }}
                      </b-badge>
                    </template>
                  </v-select>

                </b-form-group>
              </b-col>

            </b-card>
          </b-col>
        </b-row>

        <b-col md="12">
          <b-form-group
              label-cols="1"
              label-cols-lg="1"
              label="原因"
              label-for="cost_reference"
              label-size="sm"
              class="mb-1"
          >
            <b-form-textarea
                id="remark"
                size="sm"
                v-model="stockRemark"
                rows="3"
                max-rows="6"
            />
          </b-form-group>
        </b-col>
      </b-modal>

<!--      成本修改-->
      <b-modal
          id="modal-stock-cost"
          ok-only
          ok-title="确认"
          @ok="onChangeStockCost"
          cancel-title="取消"
          centered
          size="sm"
          title="库存修改"
      >
        <b-col md="12">
          <b-form-group
              label-cols="2"
              label-cols-lg="2"
              label="成本"
              label-for="cost_reference"
              label-size="sm"
              class=""
          >
            <b-form-input
                size="sm"
                v-model="stockCosts"
                type="number"
            />
          </b-form-group>
        </b-col>
      </b-modal>

<!--      分组-->
      <b-modal
          id="modal-select-category"
          no-close-on-backdrop
          ok-title="确认"
          cancel-title="取消"
          centered
          @ok="onSelectCategory"
          size="lg"
          title="分组选择"
      >
        <category-select
            :categoryOptions="category_options"
            :level="level"
            ref="categorySel">
        </category-select>

      </b-modal>

    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BFormCheckbox, BIcon, BootstrapVueIcons,
  VBTooltip, BAlert,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted} from '@vue/composition-api'
import {avatarText,getCode, getCodeLabel, toDate, toTime, getCodeColor} from '@core/utils/filter'
import stockUseList from './stockUseList'
import stockStore from '../stockStore'
import StockLogList from "@/views/apps/stocklog/StockLogList";
import Ripple from "vue-ripple-directive";
import ProductSelect from '@/views/apps/product/product-stock/ProductSelect'
import {useToast} from "vue-toastification/composition";
import WarehouseList from '@/views/apps/warehouse/WarehouseList'
import warehouselocationStore from "@/views/apps/warehouselocation/warehouselocationStore";
import WareHouseSelect from '@/views/apps/warehouse/warehouse-modal/WarehouseList'
import {getUserData} from "@/auth/utils";
import CompanySelect from "@/views/apps/company/CompanySelect"
import UserSelect from '@/views/apps/user/user-modal/UserSelect'
import brandStore from "@/views/apps/brand/brandStore";
import {useRouter} from "@core/utils/utils";
import axios from "@/libs/axios";
import CategorySelect from "@/views/apps/category/category-modal/CategorySelect";
import stockstatuschangeStore from "@/views/apps/stockstatuschange/stockstatuschangeStore";
import stockchangeapplyStore from "@/views/apps/stockchangeapply/stockchangeapplyStore";

export default {
  components: {
    CategorySelect,
    StockLogList,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BFormCheckbox,
    BIcon,
    BootstrapVueIcons,
    VBTooltip,
    BAlert,
    ProductSelect,
    WarehouseList,
    WareHouseSelect,
    CompanySelect,
    UserSelect,

  },
  data() {
    return {
      brandArray: [],
      product_brandName: '',
      checked: [],
      productid: 0,
      stockid: '',
      productcode: "",
      productname: "",
      boxquantity: 0,
      qty: 0,
      cost:0,
      stock: {},

      changedproductid: 0,
      changedproductcode: "",
      changedproductname: "",
      changedboxquantity: 0,
      changedqty: 0,
      changecost:0,
      wareHouseName: "",
      wareHouseId: null,

      wishQty: 0,

      changeStockType: [
        {label: "增加", code: 1},
        {label: "减少", code: 2}
      ],
      stockNumType: 0,
      changeNum: 0,
      remark: "",

      goodStock: 0,
      badStock: 0,
      warehouseLocationArray: [],
      goodWarehouseLocation: {},
      badWarehouseLocation: {},
      stockRemark: "",
      goodOrBad: "",
      user: {},
      fields: [
        {key: 'stock_id', label: 'ID', sortable: true},
        {key: 'status', label: '状态', sortable: true},
        {key: 'production_date', label: '生产日期', sortable: true},
        // {key: 'warehouse_name', label: '仓库', sortable: true},
        // {key: 'company_id', label: '货主', sortable: true},
        {key: 'qty', label: '库存数量', sortable: true},
        {key: 'cost', label: '成本', sortable: true},
        // {key: 'productcode', label: '69码',},
        // {key: 'name', label: '商品', sortable: true},
        // {key: 'productunit', label: '单位'},
        // {key: 'location_fullname', label: '库位', sortable: true},
        {key: 'add_time', label: '入库时间', sortable: true},
        {key: 'creator', label: '入库人', sortable: true},
        // {key: 'actions', label: '操作'},
      ],

      advanced_search_modal: false,
      advanced_search_modal_excel: false,
      modalName: '',
      companyType: '',
      supplierName:'',
      PUserName:'',
      readonly:1,
      stockCosts:0,
      product_category_name: "",
      level:3,
      category_options:[],
      stockChangeApplyReason:'',
    }
  },
  methods:{
    showSingleModal(modalName, data) {
      if (modalName === '渠道方' || modalName === '平台') {
        this.companyType = 'ChannelCustomer'
      } else if (modalName === '销售主体') {
        this.companyType = 'Group'
      } else if (modalName === '店铺') {
        this.companyType = 'Store'
      } else if (modalName === '供应商') {
        this.companyType = 'Supplier'
      }
      this.modalName = modalName
      this.$refs['singleModal'].show()

    },
    onSelectSingle() {
      let data = {}
      // this.$refs.itemList.refetchData();
      switch (this.modalName) {
        case '供应商': {
          data = this.$refs.companySelect.getSelected()
          if (data.length > 0) {
            this.supplierName = data[0].company_name;
            this.supplier_id = data[0].company_id;
          }
          break
        }
        case '采购负责人': {
          data = this.$refs.userSelect.getSelected()
          if (data.length > 0) {
            this.PUserName = data[0].full_name;
            this.PUser_id = data[0].user_id;
          }
          break;
        }
        case '仓库': {
          data = this.$refs.myWarehouseSelect.getSelected()
          if (data.length > 0) {
            this.wareHouseName = data[0].warehouse_name
            this.searchKeyWare = data[0].warehouse_id
          }
          break;
        }
          /*excelY*/
        default: {
          //statements;
          break
        }
      }
    },
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  setup() {
    const toast = useToast()
    // Register module
    if (!store.hasModule('stock')) store.registerModule('stock', stockStore)
    if (!store.hasModule('warehouselocation')) store.registerModule('warehouselocation', warehouselocationStore)
    if (!store.hasModule('brand')) store.registerModule('brand', brandStore)
    if (!store.hasModule('stockstatuschange')) store.registerModule('stockstatuschange', stockstatuschangeStore)
    if (!store.hasModule('stockchangeapply')) store.registerModule('stockchangeapply', stockchangeapplyStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('stock')) store.unregisterModule('stock')
      if (store.hasModule('warehouselocation')) store.unregisterModule('warehouselocation')
      if (store.hasModule('brand')) store.unregisterModule('brand')
      if (store.hasModule('stockstatuschange')) store.unregisterModule('stockstatuschange')
      if (store.hasModule('stockchangeapply')) store.unregisterModule('stockchangeapply')
    })

    const isGift = function (params) {
      if (params === 1) {
        return "(赠)"
      } else {
        return null
      }
    }

    const changeSelect = function (event) {
      if (event != undefined) {
        this.stockNumType = event.code
      } else {
        this.stockNumType = 0
      }

    }

    const onSelectProducts = function (data) {
      this.changedproductcode = ""
      this.changedproductname = ""
      this.changedboxquantity = 0
      this.wishQty = 0
      this.changedqty = 0
      this.changecost = 0

      //let selected = this.$refs.myProductSelect.getSelected()[0];
      let selected = data
      this.changedproductcode = selected.code
      this.changedproductname = selected.label
      this.changedboxquantity = selected.box_quantity
      this.changedproductid = selected.brand_id

      store.dispatch('stock/getChangeCost', {
        boxquantity: this.boxquantity,
        cost: this.cost,
        changedboxquantity: this.changedboxquantity
      }).then(res => {
        if (res.data.code==0){
          this.changecost = res.data.data
        }else {
          toast.error("转换后商品价格计算失败")
          this.changecost = res.data.data
        }


      })

    }



    const checksubmit = function (params) {
      if (this.changedqty <= 0) {
        toast.error('请填写转换数量!')
        return false
      }
      store.dispatch('stockchangeapply/save', {
        productid: this.productid,
        changedproductid: this.changedproductid,
        qty: this.qty,
        stockid: this.stockid,
        changedqty: this.changedqty,
        oldsubqty: this.wishQty,
        reason: this.stockChangeApplyReason,
        boxquantity:this.boxquantity,//转换前箱规
        changedboxquantity:this.changedboxquantity,//转换后箱规
      }).then(res => {
        refetchData()
        if (res.data.code == 0) {
          toast.success("提交成功,等待审核!")
        } else {
          toast.error(res.data.data)
        }
      })
    }

    const changeQty = function (event) {
      const cRegExp = /^[1-9]\d*$/
      if (!cRegExp.test(event)) {
        toast.error('转换商品库存必须是正整数!')
        this.wishQty = 0
      } else {
        if (this.changedboxquantity <= this.boxquantity) {
          if (this.wishQty > this.qty) {
            toast.error("超出当前商品库存！")
            this.wishQty = 0
            this.changedqty = 0
          } else {
            if (!cRegExp.test((this.wishQty * this.boxquantity) / this.changedboxquantity)) {
              toast.error("请填写" + (this.changedboxquantity) + "的倍数!")
              this.wishQty = 0
              this.changedqty = 0
            } else {
              this.changedqty = (this.wishQty * this.boxquantity) / this.changedboxquantity
            }
          }
        }
        if (this.changedboxquantity > this.boxquantity) {
          if (this.wishQty > this.qty) {
            toast.error("超出当前商品库存！")
            this.wishQty = 0
            this.changedqty = 0
          } else {
            if (cRegExp.test(this.changedboxquantity / this.boxquantity)) {
              if (!cRegExp.test((this.wishQty * this.boxquantity) / this.changedboxquantity)) {
                toast.error("请填写" + (this.changedboxquantity / this.boxquantity) + "的倍数!")
                this.wishQty = 0
                this.changedqty = 0
              } else {
                this.changedqty = (this.wishQty * this.boxquantity) / this.changedboxquantity
              }
            } else {
              if (!cRegExp.test((this.wishQty * this.boxquantity) / this.changedboxquantity)) {
                toast.error("请填写" + (this.changedboxquantity) + "的倍数!")
                this.wishQty = 0
                this.changedqty = 0
              } else {
                this.changedqty = (this.wishQty * this.boxquantity) / this.changedboxquantity
              }
            }

          }
        }

      }
    }

    const changeId = function (item) {
      this.productid = item.product_id
      this.stockid = String(item.stock_id)
    }

    const changeStock = function (item) {
      this.stockid = item.stock_id
      this.stockNumType = 0
      this.changeNum = 0
      // this.stockid = 0
      this.remark = ""
    }

    const getProduct = function (params) {
      this.brandArray = []
      this.wishQty = 0
      let d = this.brandArray
      store.dispatch('brand/searchproductlabelrelation',{id:params.product_id}).then(res => {
        let a = res.data.data
        for (let i = 0; i < a.length; i++) {
          // d.push({"label": a[i].name, "brand_id": a[i].product_id})
          d.push({"label":"商品ID："+a[i].product_id  +"   商品名称："+ a[i].name, "brand_id": a[i].product_id,"code":a[i].code,"box_quantity":a[i].box_quantity})
        }
      })
      this.productid = params.product_id
      this.stockid = params.stock_id
      this.productcode = params.ext.productcode
      this.productname = params.name
      this.boxquantity = params.ext.boxquantity
      this.qty = params.qty-params.ext.stockItemLockQty
      this.cost = params.cost

      this.changedproductcode = ""
      this.changedproductname = ""
      this.changedboxquantity = 0
      this.changedqty = 0
      this.changedproductid = 0
      this.changecost = 0
      this.product_brandName = ""
    }

    const onChangeStock = function (params) {
      const cRegExp = /^[1-9]\d*$/
      if (!cRegExp.test(this.changeNum)) {
        toast.error('数量必须是正整数!')
        return false
      }
      if (this.stockNumType == 0) {
        toast.error('请选择操作类型操作类型!')
        return false
      }
      if (this.changeNum === undefined || this.changeNum === 0) {
        toast.error('请填写数量!')
        return false
      }
      if (params=='wuliu'){
        store.dispatch('stock/stockModify', {
          type: this.stockNumType,
          qty: this.changeNum,
          stockid: this.stockid,
          reason: this.remark
        }).then(res => {
          refetchData()
          if (res.data.data === "success") {
            toast.success("修改成功!")
          } else {
            toast.error("修改失败!")
          }
        })
      }
      if (params=='yanfa'){
        store.dispatch('stock/stockModifyYF', {
          type: this.stockNumType,
          qty: this.changeNum,
          stockid: this.stockid,
          reason: this.remark
        }).then(res => {
          refetchData()
          if (res.data.data === "success") {
            toast.success("修改成功!")
          } else {
            toast.error("修改失败!")
          }
        })
      }


    }

    const transfer = function () {
      if (this.goodWarehouseLocation == null || this.badWarehouseLocation == null) {
        toast.error("请选择转换后仓位！")
        return false
      }
      if ((parseInt(this.goodStock) + parseInt(this.badStock)) > this.stock.qty) {
        toast.error("超出最大数量!")
        return false
      }
      store.dispatch('stockstatuschange/save', {
        stockId: this.stock.stock_id,
        goodstock: this.goodStock,
        goodlocation: this.goodWarehouseLocation.code,
        badstock: this.badStock,
        badlocation: this.badWarehouseLocation.code,
        reason: this.stockRemark,
        stockQty:this.stock.qty,
        stockLocation:this.stock.location_id
      }).then(res => {
        if (res.data.code == 0) {
          toast.success("保存成功,等待审核！")
        } else {
          toast.error(res.data.data)
        }
        refetchData()
      })
    }

    const changeTransfer = function (params) {
      this.warehouseLocationArray.length = 0
      this.goodWarehouseLocation = null
      this.badWarehouseLocation = null
      this.goodStock = 0
      this.badStock = 0
      this.stock = params
      this.goodOrBad = getCodeLabel("stock_status", this.stock.status)


      store.dispatch('warehouselocation/search', {warehouse_id: params.warehouse_id}).then(res => {
        let data = res.data.data.list
        let val = this.stock.location_id.split(",")
        for (let i = 0; i < data.length; i++) {
          this.warehouseLocationArray.push({label: data[i].location_fullname, code: data[i].location_id,zh_label:getCodeLabel('warehouse_location', data[i].location_fullname)})
          if (this.stock.status == 0) {
            for (let j = 0; j < val.length; j++) {
              if (val[j] == data[i].location_id) {
                this.goodWarehouseLocation = {label: data[i].location_fullname, code: data[i].location_id,zh_label:getCodeLabel('warehouse_location', data[i].location_fullname)}
              }
            }
          }
          if (this.stock.status == 1) {
            for (let j = 0; j < val.length; j++) {
              if (val[j] == data[i].location_id) {
                this.badWarehouseLocation = {label: data[i].location_fullname, code: data[i].location_id,zh_label:getCodeLabel('warehouse_location', data[i].location_fullname)}
              }
            }
          }
        }
      })
    }

    const changeWarehouseLocation = function (params, event) {
      if (params === "good") {
        // console.log("good", this.goodWarehouseLocation)
        // console.log("event", event)
      }
      if (params === "bad") {
        // console.log("bad", this.badWarehouseLocation)
        // console.log("event", event)
      }
    }

    const changeStockNum = function (params) {
      if ((parseInt(this.goodStock) + parseInt(this.badStock)) > this.stock.qty) {
        this.goodStock = 0
        this.badStock = 0
        toast.error("超出最大数量!")
      } else {
        if (params === "good") {
          this.badStock = parseInt(this.stock.qty) - parseInt(this.goodStock)
        }
        if (params === "bad") {
          this.goodStock = parseInt(this.stock.qty) - parseInt(this.badStock)
        }
      }
    }

    const advanced_search = function () {
      this.advanced_search_modal = this.advanced_search_modal ? false : true;
    }

    const searchByCondition = function () {
      refetchData()
    }

    const changeBrandId = function (event) {
      if (event != null) {
        this.onSelectProducts(event);
      }
    }

    /* const getBrand = function (d,productId) {
       store.dispatch('brand/searchproductlabelrelation',{id:productId}).then(res => {
         let a = res.data.data.list
         for (let i = 0; i < a.length; i++) {
           d.push({"label": a[i].label_id, "brand_id": a[i].product_id})
         }
       })
     }*/


    const resetCondition = function () {
      this.wareHouseName = ''
      this.searchKeyWare = null
      this.searchKeyName = null
      this.supplier_id = null
      this.supplierName = ''
      this.PUser_id = null
      this.PUserName = ''
      this.code = ''
      this.product_category_id=''
      this.product_category_name=''
      this.negativeStock=0
      this.stockIds=''

      refetchData()
    }

    const sendEveryDayStockToEmail = function () {
      store.dispatch('stock/sendEveryDayStockToEmail').then(res => {
        if (res.data.code==0){
          toast.success("发送成功,请去邮箱查收")
        }else {
          toast.error("发送失败")
        }

      })
    }

    const onChangeStockCost = function () {
      if (this.stockCosts==null){
        toast.error("请填写成本")
        return false
      }
      store.dispatch('stock/changeStockCost',{id:this.stockid,cost:this.stockCosts}).then(res => {
        if (res.data.code==0){
          toast.success(res.data.data)
          refetchData()
          this.stockCosts = 0
        }else {
          toast.error(res.data.data)
        }
      })
    }

    const updateSalesOrderCost = function (params) {
      axios.get(process.env.VUE_APP_BASE_URL+'/api/bugfix/updatesalesordercost?stockid='+params.stock_id)
          .then(res => {
            if (res.data.code==0){
              toast.success(res.data.data)
              refetchData()
            }else {
              toast.error(res.data.data)
            }
          })
    }

    const onSelectCategory = function () { //赋值回显
      this.product_category_id = this.$refs.categorySel.categoryId +","+ this.$refs.categorySel.categoryTowId + "," +this.$refs.categorySel.categoryThreeId;
      this.category_options = [];
      this.category_options.push(
          {
            "label": this.$refs.categorySel.categoryName,
            "category_id": this.$refs.categorySel.categoryId,
          },
          {
            "label": this.$refs.categorySel.categoryTowName,
            "category_id": this.$refs.categorySel.categoryTowId,
          },
          {
            "label": this.$refs.categorySel.categoryThreeName,
            "category_id": this.$refs.categorySel.categoryThreeId,
          },
      );
      var new_content = this.$refs.categorySel.categoryName+ "/";
      new_content += this.$refs.categorySel.categoryTowName + "/";
      new_content += this.$refs.categorySel.categoryThreeName + "/";
      this.product_category_name = new_content;
    }

    const createExcel = function () {
      axios({
            url: '/api/exportexcelfile/createExcel',
            params:{
              search_key_ware: searchKeyWare.value,
              search_key_name: searchKeyName.value,
              supplier_id: supplier_id.value,
              PUser_id: PUser_id.value,
              code:code.value,
              productId:router.route.value.query==null?null:router.route.value.query.productId,
              product_category_id: product_category_id.value,
              negativeStock: negativeStock.value,
              stockIds: stockIds.value,
            },
            method:'get',
            responseType: 'blob',
          }
      ).then(res => {
        toast.success("导出成功")
        let data = new Blob([res.data], { type: 'application/vnd.ms-excel' })   //接收的是blob，若接收的是文件流，需要转化一下
        let filename = "stock-"+new Date().getTime();
        /*-------------*/
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = URL.createObjectURL(data);
        link.setAttribute('download',filename+'.xlsx')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })

    }

    const router = useRouter()

    const {
      searchList,
      searchALlList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKeyName, searchKeyWare, supplier_id, PUser_id,code,product_category_id,negativeStock,stockIds,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      allCost,
      click,
      // UI
    } = stockUseList({
      productId:router.route.value.query==null?null:router.route.value.query.productId
    })
    return {
      searchList,
      searchALlList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKeyName, searchKeyWare, supplier_id, PUser_id,code,product_category_id,negativeStock,stockIds,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      allCost,

      // Filter
      avatarText,
      getCodeLabel,
      getCodeColor,
      getCode,
      changeBrandId,
      /* getBrand,*/

      // UI
      toDate,
      toTime,
      changeId,
      isGift,
      checksubmit,
      getProduct,
      onSelectProducts,
      changeQty,
      onChangeStock,
      changeSelect,
      changeStock,
      transfer,
      changeTransfer,
      changeWarehouseLocation,
      changeStockNum,
      advanced_search,
      searchByCondition,
      resetCondition,
      sendEveryDayStockToEmail,
      onChangeStockCost,
      updateSalesOrderCost,
      onSelectCategory,
      createExcel,

    }
  },
  created() {
    const userData = getUserData()
    this.user = userData
    this.stockCost = getCodeLabel("setting", "stock_cost")
    let array = this.stockCost.split(",")
    for (let i = 0; i < array.length; i++) {
      if (userData.user_id == array[i]) {
        this.readonly = 0
      }
    }

  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
