import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {toDate} from "@core/utils/filter";

export default function stockUseList(config) {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  let tableColumns = [
    { key: 'warehouse_id', label: '仓库'},
    { key: 'location_fullname', label: '库位'},
    { key: 'code', label: '商品69码'},
    { key: 'name', label: '商品名称'},
    { key: 'productunit', label: '单位' },
    { key: 'boxquantity', label: '单箱数量', sortable: true },
    { key: 'qty', label: '库存', sortable: true },
    { key: 'canUseQty', label: '可用库存', sortable: true },
    { key: 'lockQty', label: '占用库存', sortable: true },
    { key: 'all_cost', label: '库存金额', sortable: true },
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const searchKeyWare = ref('')
  const searchKeyName = ref('')
  const supplier_id = ref('')
  const PUser_id = ref('')
  const orderBy = ref('stock_id')
  const isSortDirDesc = ref(true)
  const click = ref(0)
  const allCost = ref(0)
  const code = ref('')
  const product_category_id = ref('')
  const negativeStock = ref(0)
  const stockIds = ref('')

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit, click], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    store
      .dispatch('stock/operateSearchStock', {
        search_key_ware: searchKeyWare.value,
        search_key_name: searchKeyName.value,
        supplier_id: supplier_id.value,
        PUser_id: PUser_id.value,
        code:code.value,
        start: start.value,
        limit: limit.value,
        order_by: 'product_id',
        order_desc: isSortDirDesc.value === true ? 'desc':'',
        productId:config.productId,
        orderBys: orderBy.value,
        orderDescs: isSortDirDesc.value === true ? 'desc' : '',
        product_category_id: product_category_id.value,
        negativeStock: negativeStock.value,
        stockIds: stockIds.value,
      })
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        listTotals.value = data.ext.totals
        allCost.value = data.ext.allCost
        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '库存列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const searchALlList = (ctx, callback) => {
    store
      .dispatch('stock/searchAllInfo', {
        search_key_ware: searchKeyWare.value,
        search_key_name: searchKeyName.value,
        start: start.value,
        limit: limit.value,
        order_by: orderBy.value,
        order_desc: isSortDirDesc.value === true ? 'desc':'',
      })
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        listTotals.value = data.ext.totals
        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '库存列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*


  return {
    searchList,
    searchALlList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKeyWare, searchKeyName,supplier_id, PUser_id,code,product_category_id,negativeStock,stockIds,
    orderBy,
    isSortDirDesc,
    refListTable,

    refetchData,
    click,
    allCost,
    // Extra Filters
  }
}
