<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0 list-table"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <!-- Search -->
          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                  v-model="searchKey"
                  class="d-inline-block mr-1"
                  placeholder="搜索关键字..."
              />
              <!--
              <b-button
                  variant="primary"
                  :to="{ name: 'apps-product-edit'}"
              >
                <span class="text-nowrap">添加</span>
              </b-button>
              -->
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
          select-mode="single"
          selectable
          @row-selected="onRowSelected"
      >

        <!-- Columns -->
        <template #cell(code)="data">
          [#{{ data.item.id }}]{{data.value}}
        </template>
        <template #cell(status)="data">
          {{ getCodeLabel('product_status', data.item.status) }}
        </template>

        <!--        product_attribute-->
        <template #cell(product_attribute)="data">
          {{ getCodeLabel('product_attribute', data.item.product_attribute) }}
        </template>

        <!--        product_unit-->
        <template #cell(product_unit)="data">
          {{ getCodeLabel('product_unit', data.item.product_unit) }}
        </template>

        <!-- Column: State -->
        <template #cell(state)="data">
          <b-link
              @click="changeState(data.item)"
          >
            <b-badge
                pill
                :variant="`light-${resolveStateCode(data.item.state).color}`"

            >
              {{ resolveStateCode(data.item.state).label }}
            </b-badge>
          </b-link>
        </template>


        <template #cell(stockStr)="data">
          <b-link
              @click="showStock(data.item)"
              class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.stockStr }}
          </b-link>
        </template>
        <!--
                <template #cell(stock)="data">
                  <b-dropdown
                      variant="link"
                      no-caret
                  >

                    <template #button-content>
                      <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="align-middle text-body"
                      />
                    </template>
                    <b-dropdown-item @click="showStock(data.item.product_id)" >
                      <feather-icon icon="FileTextIcon" />
                      <span class="align-middle ml-50">查看库存</span>
                    </b-dropdown-item>

                  </b-dropdown>
                </template>-->

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>

      </div>
      <b-modal
          id="stockModal"
          ref="stockModal"
          ok-only
          ok-title="确认"
          @hide="hideModal"
          cancel-title="取消"
          centered
          size="lg"
          title="库存列表"
      >
        <stock-show-modal
            ref="stockShowModal" :productId="productId" :isShow="false"
        >
        </stock-show-modal>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BFormCheckbox, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BModal
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText, getCode, getCodeColor, getCodeLabel, getCodeOptions } from '@core/utils/filter'
import productUseList from './productUseSelect'
import productStore from '../productStore'
import StockShowModal from '@/views/apps/stock/stock-show-modal/StockShowModal'

export default {
  components: {
    StockShowModal,
    BCard,
    BModal,
    BRow,
    BCol,
    BFormInput,
    BFormCheckbox,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  data() {
    return {
      selected: [],
      productId: 0,
      stockStr: '查看',
      selectedItem: {},
    }
  },

  methods: {
    getSelected: function () {
      return this.selected
    },
    onRowSelected(items) {
      this.selected = items
    },
    showStock(item) {
      this.selectedItem = item
      this.productId = item.id
      this.$refs['stockModal'].show()
    },
    hideModal() {
      this.stockStr = this.$refs.stockShowModal.getStockStr()
      this.selectedItem['stockStr'] = this.stockStr
    },
  },
  setup() {
    // Register module
    if (!store.hasModule('product')) store.registerModule('product', productStore)
    // if (!store.hasModule('stock')) store.registerModule('stock', stockStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('product')) store.unregisterModule('product')
      // if (store.hasModule('stock')) store.unregisterModule('stock')
    })

    /*const addnewpro = function(product_id) {
      store.dispatch('purchaseorderitem/addnewpro', {
        orderid:props.order_id,
        pid:product_id
      }).then(res => {
        toast.success('数据已保存!')
        refetchData()
      })
    }*/

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // UI
      resolveProductTypeCode,
      resolveStateCode,
    } = productUseList()

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      // Filter
      avatarText,

      // UI
      resolveProductTypeCode,
      resolveStateCode,
      getCodeLabel,
      getCode,
      getCodeColor,
      getCodeOptions,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
