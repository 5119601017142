import axios from '@axios'
import qs from 'qs'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    search(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/category/search', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    view(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/category/view', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    edit(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/category/edit', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    save(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/category/save', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    state(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/category/state', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    select(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            //.get('/api/category/select', {params: params})
            .get('/one/category/v1/search_tree', {params: params})
            //.get('/api/category/search_tree', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    searchByIds(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('api/category/searchByIds', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
  },
}
