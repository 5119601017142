<template>
    <b-row>
        <b-col md="4">
            <b-form-group
                    label="一级分类"
                    class="mb-1"
            >
                <v-select
                        id="category_id"
                        :options="category_Array"
                        :clearable="true"
                        v-model="categoryName"
                        @input="changeCategoryId($event)"
                        class="select-size-sm"
                />
            </b-form-group>
        </b-col>

        <b-col
                v-if="levelTow"
                md="4"
        >
            <b-form-group
                    label="二级分类"
                    class="mb-1"
            >
                <v-select
                        id="category_tow_id"
                        :options="category_tow_Array"
                        :clearable="true"
                        v-model="categoryTowName"
                        @input="changeCategoryTowId($event)"
                        class="select-size-sm"

                />
            </b-form-group>
        </b-col>

        <b-col
                v-if="levelThree"
                md="4"
        >
            <b-form-group
                    label="三级分类"
                    class="mb-1"
            >
                <v-select
                        id="category_three_id"
                        :options="category_three_Array"
                        :clearable="true"
                        v-model="categoryThreeName"
                        @input="changeCategoryThreeId($event)"
                        class="select-size-sm"
                />
            </b-form-group>
        </b-col>
    </b-row>
</template>

<script>
    import {
        BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
        BBadge, BDropdown, BDropdownItem, BPagination,
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import store from '@/store'
    import { ref, onUnmounted } from '@vue/composition-api'
    import { avatarText ,toDate,} from '@core/utils/filter'
    import categoryUseList from "@/views/apps/category/category-modal/categoryUseList";
    import categoryStore from "@/views/apps/category/categoryStore";

    export default {
        components: {
            BCard,
            BRow,
            BCol,
            BFormInput,
            BButton,
            BTable,
            BLink,
            BBadge,
            BDropdown,
            BDropdownItem,
            BPagination,
            vSelect,
        },
        data() {
            return {
                //v-model
                categoryName:'',
                categoryTowName: '',
                categoryThreeName: '',


                //options
                category_Array: [],
                category_tow_Array: [],
                category_three_Array: [],

                categoryId:0,
                categoryTowId:0,
                categoryThreeId:0,

                categoryAll:{},

                levelTow:true,
                levelThree:true,

            }
        },

      //showAll = 'true' 显示所有分类，否则只显示有效
        props: ["categoryOptions", "level", "showAll"],
        setup() {
            // Register module
            if (!store.hasModule('category')) store.registerModule('category', categoryStore)

            // UnRegister on leave
            onUnmounted(() => {
                if (store.hasModule('category')) store.unregisterModule('category')
            })

            const edit = function () {
                // 拿到props,初始化数据
                if(this.level === 1){
                    this.levelTow = false;
                    this.levelThree = false;
                }
                else
                    if(this.level === 2){
                    this.levelThree = false;
                }

                if(this.categoryOptions.length ===1){
                    this.categoryName = this.categoryOptions[0].label ;
                    this.categoryId = this.categoryOptions[0].category_id;

                }else
                    if(this.categoryOptions.length ===2){
                        this.categoryName = this.categoryOptions[0].label ;
                        this.categoryTowName = this.categoryOptions[1].label ;
                        this.categoryId = this.categoryOptions[0].category_id ;
                        this.categoryTowId = this.categoryOptions[1].category_id ;
                    }else
                        if(this.categoryOptions.length ===3){
                            this.categoryName = this.categoryOptions[0].label;
                            this.categoryTowName = this.categoryOptions[1].label ;
                            this.categoryThreeName = this.categoryOptions[2].label ;
                            this.categoryId = this.categoryOptions[0].category_id ;
                            this.categoryTowId = this.categoryOptions[1].category_id ;
                            this.categoryThreeId = this.categoryOptions[2].category_id ;
                        }

                this.category_Array = [];
                this.category_tow_Array = [];
                this.category_three_Array= [];
                //初始化全部category
                store.dispatch('category/select',{disable:this.showAll}).then(res => { //获得一级分类下拉数据
                    this.categoryAll = res.data.data;
                    for (let i = 0; i < this.categoryAll.length ; i++) {
                        //初始化一级category
                        if(this.categoryAll[i].level === 1){
                            this.category_Array.push({
                                "label": this.categoryAll[i].name,
                                "category_id": this.categoryAll[i].category_id,
                            });
                        }

                        //初始化二级category
                        if(this.categoryAll[i].level === 2 && this.categoryAll[i].parent_id === this.categoryId){
                            this.category_tow_Array.push({
                                "label": this.categoryAll[i].name,
                                "category_id": this.categoryAll[i].category_id,
                            });
                        }

                        //初始化三级category
                        if(this.categoryAll[i].level === 3 && this.categoryAll[i].parent_id === this.categoryTowId){
                            this.category_three_Array.push({
                                "label": this.categoryAll[i].name,
                                "category_id": this.categoryAll[i].category_id,
                            });
                        }
                    }
                })
            }

            const changeCategoryId = function (event) {
                this.categoryTowName = '';
                this.categoryThreeName = '';

                this.categoryTowId = 0;
                this.categoryThreeId = 0;

                if(this.categoryName ===null || this.categoryName === ''){
                    this.categoryId = 0;
                    this.categoryName = '';
                }else{
                    this.category_tow_Array = [];
                    this.category_three_Array= [];
                    this.categoryId = event.category_id;
                    this.categoryName = event.label;
                    for (let i = 0; i < this.categoryAll.length; i++) {
                        if(this.categoryAll[i].parent_id === this.categoryId){
                            this.category_tow_Array.push({
                                "label": this.categoryAll[i].name,
                                "category_id": this.categoryAll[i].category_id,
                            });
                        }
                    }
                }
            }

            const changeCategoryTowId = function (event) {
                this.categoryThreeId = 0;
                this.categoryThreeName = '';
                if(this.categoryTowName ===null || this.categoryTowName === ''){
                    this.categoryTowId = 0;
                    this.categoryTowName = '';
                }else{
                    this.category_three_Array= [];
                    this.categoryTowId = event.category_id;
                    this.categoryTowName = event.label;
                    for (let i = 0; i < this.categoryAll.length; i++) {
                        if(this.categoryAll[i].parent_id === this.categoryTowId){
                            this.category_three_Array.push({
                                "label": this.categoryAll[i].name,
                                "category_id": this.categoryAll[i].category_id,
                            });
                        }
                    }
                }
            }

            const changeCategoryThreeId = function (event) {
                if(this.categoryThreeName === null || this.categoryThreeName === ''){
                    this.categoryThreeId = 0;
                    this.categoryThreeName = '';
                }else{
                    this.categoryThreeId = event.category_id;
                    this.categoryThreeName = event.label;
                }
            }


            const {
                searchList,
                tableColumns,
                limit,
                start,
                listTotals,
                dataMeta,
                limitOptions,
                searchKey,
                orderBy,
                isSortDirDesc,
                refListTable,
                refetchData,

                // UI
                resolveCategoryTypeCode,
                resolveStateCode,
            } = categoryUseList()

            return {

                edit,
                changeCategoryId,
                changeCategoryTowId,
                changeCategoryThreeId,

                searchList,
                tableColumns,
                limit,
                start,
                listTotals,
                dataMeta,
                limitOptions,
                searchKey,
                orderBy,
                isSortDirDesc,
                refListTable,
                refetchData,

                // Filter
                avatarText,
                toDate,

                // UI
                resolveCategoryTypeCode,
                resolveStateCode,
            }
        },
        created() {
            this.id = this.$route.query.id || 0;
            this.edit();
        },
    }
</script>

<style lang="scss" scoped>
    .per-page-selector {
        width: 90px;
    }
</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>
